import React, { useEffect, useState } from 'react';

class SelectionItem {
    index;
    item;
    key;

    constructor(index, item, key) {
        this.index = index;
        this.item = item;
        this.key = key;
    }
}

export const useSelection = (items, onChange) => {

    const [selection, setSelection] = useState([]);

    const isIndexSelected = (index) => {
        return typeof selection[index] !== 'undefined';
    }

    const isItemSelected = (item) => getItemIndex(item) !== -1;
    const getItemIndex = (item) => selection.findIndex(i => i.item.key === item.key);
    const getSelectedIndexes = () => selection.map(sel => sel.index);

    /*
    const _getAllChildren = (node) => {
        const getChildren = (child, result) => {
            result.push(child);
            child.children.forEach(i => getChildren(i, result));
        }

        const result = [];
        node.children.forEach(child => getChildren(child, result));
        return result;
    }*/
    
    const toggleSelection = (item) => {
        const copy = [...selection];
        const i = getItemIndex(item);
        let itemShouldBeActive = i === -1;
        if (!itemShouldBeActive) {
            copy.splice(i, 1);
        } else {
            const index = items.findIndex(i => i.key === item.key);
            copy.push(new SelectionItem(index, item, item.key));
        }

        const children = item.children;
        
        if (itemShouldBeActive) {
            children.forEach(child => {
                const orgIndex = items.findIndex(c => c.key === child.key);
                if (copy.findIndex(c => c.key === child.key) === -1) {
                    copy.push(new SelectionItem(orgIndex, child, child.key));
                }
            });

        } else {
            let count = children.length;
            for (var j = count - 1; j >= 0; j--) {
                const childIndex = copy.findIndex(c => c.key === children[j].key);
                if (childIndex !== -1) {
                    copy.splice(childIndex, 1);
                } else {
                    const orgIndex = items.findIndex(i => i.key === children[j].key);
                    copy.push(new SelectionItem(orgIndex, children[j], children[j].key));
                }
            }
        }

        /*
        if (itemShouldBeActive) {

            children.forEach(child => {
                const orgIndex = items.findIndex(c => c.key === child.key);
                if (copy.findIndex(c => c.key === child.key) === -1) {
                    copy.push(new SelectionItem(orgIndex, child, child.key));
                }
            });

        } else {
            let count = children.length;
            for (var j = count - 1; j >= 0; j--) {
                const childIndex = copy.findIndex(c => c.key === children[j].key);
                if (childIndex !== -1) {
                    copy.splice(childIndex, 1);
                } else {
                    const orgIndex = items.findIndex(i => i.key === children[j].key);
                    copy.push(new SelectionItem(orgIndex, children[j], children[j].key));
                }
            }
        }
        */
      
        setSelection(copy);
        onChange(copy.map(i => i.item));
    }

    return { selection, isIndexSelected, isItemSelected, toggleSelection, getSelectedIndexes };
}