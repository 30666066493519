export default {
	"rows": [
			[
				"Natural Gas",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-356.74,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Office Supplies",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-6505.84,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Marketing",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-997226.82,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Hosting Fees",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-748.63,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Audit and Accounting",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-3960.35,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Miscellaneous expenses",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-461.26,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Accounts Payable",
				"BSC",
				"Main Transactions",
				"Western Hemisphere",
				1846771.91,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Amortization",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-1869.06,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Legal",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-18579.96,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Intercompany Interest Expense",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				null,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Insurance",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-9564.4,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Printing and Publications",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-730.42,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Electricity",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-384.42,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Special events",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-1040.96,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Other Supplies",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-3732.84,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Contractual Services",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-3177.41,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Advertising",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-3025.81,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Training",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-241.38,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Dues, Licenses and Permits",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-19108.33,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Gift and donations",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-302.44,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Interest",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-8903.6,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Repairs and maintenance",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-1463.41,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Taxes",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-102105.78,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Consulting",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-20656.46,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Postage",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-3439.86,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Travel",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-5474.03,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Rent",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-19614.15,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Telephone",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-19020.69,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Bad Debt Expense",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-232.38,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Books and Subscriptions",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-1987.7,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Conference and seminars",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-8742.38,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Telephone - Cellular",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-6323.96,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"COGS - Product Sales",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-581973.47,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			],
			[
				"Bank charges",
				"PLC",
				"Main Transactions",
				"Western Hemisphere",
				-268.25,
				"Balance Sheet",
				"BI360",
				"Narrative Paragraph 3 Line 99"
			]
		],
		"columns": [
			{
				"name": "d_Account.Description",
				"text": "Account Desciption",
				"type": "group",
				"dataType": "text",
				"includeAll": false,
				"rqlAggregation": "group"
			},
			{
				"name": "d_Account.AccountType",
				"text": "AccountType",
				"type": "group",
				"dataType": "text",
				"includeAll": false,
				"rqlAggregation": "none"
			},
			{
				"name": "d_Category.Description",
				"text": "Category Desciption",
				"type": "group",
				"dataType": "text",
				"includeAll": false,
				"rqlAggregation": "none"
			},
			{
				"name": "EntityCorr.Description",
				"text": "",
				"type": "group",
				"dataType": "text",
				"includeAll": false,
				"rqlAggregation": "none"
			},
			{
				"name": "AP_F_Trans.Value1",
				"text": "Value1",
				"type": "measure",
				"dataType": "currency",
				"includeAll": false,
				"rqlAggregation": "sum"
			},
			{
				"name": "d_dim0.Description",
				"text": "Department Desciption",
				"type": "group",
				"dataType": "text",
				"includeAll": false,
				"rqlAggregation": "none"
			},
			{
				"name": "d_dim0.CreatedBy",
				"text": "Created By",
				"type": "group",
				"dataType": "text",
				"includeAll": false,
				"rqlAggregation": "none"
			},
			{
				"name": "d_dim2.Description",
				"text": "Line Details Desciption",
				"type": "group",
				"dataType": "text",
				"includeAll": false,
				"rqlAggregation": "none"
			}
		]
}
