import React, { useEffect, useState } from 'react';
import './App.css';
import {
	DetailsList,
	MarqueeSelection,
	initializeIcons,
	Selection,
	DetailsListLayoutMode
} from '@fluentui/react';

import queryResponse from './queryResponse';

initializeIcons();

const rawRows = queryResponse.rows;

const Grid = ({ rawColumns }) => {

	const [context, setContext] = useState(null);
	const [columns, setColumns] = useState([]);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		let processedColumns = rawColumns.map((col, index) => {
			return {
				key: col.key,
				name: col.name,
				fieldName: ['field' + index]
			};
		});
	
		let processedRows = rawRows.map(row => {
			let ret = {}
			row.forEach((value, index) => {
				ret['field' + index] = value;
			});
			return ret;
		});
		setColumns(processedColumns);
		setRows(processedRows);

	}, [rawColumns, rawRows]);

	let selection = new Selection();

	const dragDropEvents = {
		canDrop: () => {
			return true;
		},
		canDrag: () => {
			return true;
		}
	}

	const columnReorderOptions = {
		frozenColumnCountFromStart: 0,
		frozenColumnCountFromEnd: 0,
		handleColumnReorder: (draggedIndex, targetIndex) => {
			console.log('Handle reorder', draggedIndex, targetIndex);
			const draggedItems = columns[draggedIndex];
			const newColumns = [...columns];
			newColumns.splice(draggedIndex, 1);
			newColumns.splice(targetIndex, 0, draggedItems);
			setColumns(newColumns);
		},
	};

	if (!columns.length)
		return null;

	return (
		<MarqueeSelection selection={selection} style={{ padding: "2rem" }}>
			<DetailsList
				items={rows}
				columns={columns}
				selection={selection}
				dragDropEvents={dragDropEvents}
				setKey="key"
				layoutMode={DetailsListLayoutMode.justified}
				columnReorderOptions={columnReorderOptions}
				checkButtonAriaLabel="Row checkbox"
				compact
			/>
		</MarqueeSelection>
	);
}

export default Grid;