import React, { useEffect, useMemo, useState } from 'react';
import response from './response-full.json';

const processNode = (node, parent, parentKey) => {
    if (!parent || typeof parent === 'undefined') {
        parent = ''
    }
    node.children.forEach((n, i) => {
        node.children[i].key = parent + node.children[i].name;
        node.children[i].parent = parent;
        node.children[i].parentKey = parentKey;
        node.children[i].children = processNode(node.children[i], parent+node.children[i].key, node.children[i].name);
    });
    return node.children;
}

const processNodes = (nodes) => {
    const copy = nodes.slice();
    copy.forEach((_, i) => {
        copy[i].key = copy[i].name;
        copy[i].parent = '';
        copy[i].parentKey = '';
        copy[i].children = processNode(copy[i], copy[i].name, '');
    });
    return copy;
}

const getItem = (node, result, parent) => {
    result.push({...node, parent, key : parent+node.name });    
    node.children.forEach(n => getItem(n, result, parent + node.name));
}

const getItems = (nodes) => {
    const result = [];
    nodes.forEach(n => getItem(n, result, ''));
    return result;
}

export const useNodes = () => {

    const [allNodes, setNodes] = useState([]);
    const [allItems, setItems] = useState([]);

    useEffect(() => {
        setNodes(processNodes(response.rootNodes));
        setItems(getItems(response.rootNodes));
    }, []);

    const nodes = useMemo(() => allNodes, [allNodes]);
    const items = useMemo(() => allItems, [allItems]);

    return { nodes, items };
}