import React, { useState, useMemo, useEffect } from 'react';
import { Stack, Check, mergeStyleSets, IconButton, Icon, Dropdown, SearchBox, CommandBar, SelectableOptionMenuItemType, Text } from '@fluentui/react';
import { useSelection } from './useSelection';
import Fuse from 'fuse.js/dist/fuse.min.js';
import { useNodes } from './useNodes';

const VIEWS = {
    COLUMNS : 'columns',
    FILTERS: 'filters'
}


function filter(array, searchResultKeys) { 

    const _inSearchResult = (node) => searchResultKeys.indexOf(node.name) > -1;

    const _processNode = (node) => {
        let filtered = node.children.filter(_inSearchResult);
        filtered.forEach((_, i) => {
            /*if (_inSearchResult(filtered[i].name) && !filtered[i].children.some(_inSearchResult)) {
                return;
            }*/
            const children = _processNode(filtered[i]);
            filtered[i].children = children;
        });
        return filtered;
    }
    
    const _processNodes = (nodes) => {
        const result = nodes.map((_, i) => {
            if (_inSearchResult(nodes[i].name) || nodes[i].children.some(_inSearchResult)) {
                const children = _processNode(nodes[i]);
                if (!children.length) {
                    return null;
                }
                nodes[i].children = children;
                return nodes[i];
            }
            return null;
        }).filter(i => i);
        return result;
    }
    
    return _processNodes([...array]).slice();
}

const defaultOptions = {
    isCaseSensitive: false,
    findAllMatches: true,
    keys: ['text'],
    minMatchCharLength: 2,
    threshold: 0.2,
    distance: 100,
    location: 0
}

export const initSearch = (data) => new Fuse(data, defaultOptions);

const commonStyles = {
    display: 'inline-block',
    cursor: 'default',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    background: 'none',
    backgroundColor: 'transparent',
    border: 'none',
  };
  const classNames = mergeStyleSets({
    item: {
        display:'flex', 
        flexDirection:'row', 
        justifyContent:'flex-start',
        alignItems:'center',
        alignContent:'center',
        height: 40,
        width: '100%'
    },
    // Overwrites the default style for Button
    check: [commonStyles, { padding: '11px 8px' }],
    cell: [
      commonStyles,
      {
        overflow: 'hidden',
        height: 36,
        padding: 8,
        userSelect: 'none',
      },
    ],
    table: {
        borderCollapse: 'collapse',
        width: '100%'
    }
  });


const processNode = (node, parent) => {
    if (!parent || typeof parent === 'undefined') {
        parent = ''
    }
    node.children.forEach((n, i) => {
        node.children[i].key = parent + node.children[i].name;
        node.children[i].parent = parent;
        node.children[i].children = processNode(node.children[i], parent+node.children[i].name);
    });
    return node.children;
}

const processNodes = (nodes) => {
    const copy = nodes.slice();
    copy.forEach((_, i) => {
        copy[i].key = copy[i].name;
        copy[i].children = processNode(copy[i], copy[i].name);
    });
    return copy;
}


const processNodesForSearch = (searchResult, rootNode) => {
    if (!searchResult || !searchResult.length) {
        return [];
    }
    const result = Object.assign({}, rootNode);
    const keys = [...new Set(searchResult.map(i => i.name)), ...new Set(searchResult.map(i => i.parentKey))].filter(i => i); // Unique
    result.children = filter(result.children, keys);
    return result;
}

export const CustomTree = ({ onChange }) => {

    const { nodes, items } = useNodes();

    const selection = useSelection(items, onChange); 

    const roots = [].concat(nodes.map(n => ({ key : n.name, text: n.text })));

    const [visibleNodes, setVisibleNodes] = useState([]);
    const [expanded, setExpansion] = useState({});
    const [activeRoot, setActiveRoot] = useState(null);
    const [searchable, setSearchable] = useState([]);
    const [currentView, setCurrentView] = useState(VIEWS.COLUMNS);
    const [filters, setFilters] = useState([ 
        {name : 'Account code', id : 'acc_code'},
        {name : 'Created at', id : 'created_at'},
        {name : 'Amount', id : 'amount'}
    ]);

     
    const _items = [
        {
            key: VIEWS.COLUMNS,
            text: 'Columns',
            iconProps: { iconName: 'DoubleColumn' },
            onClick: () => setCurrentView(VIEWS.COLUMNS),
            disabled: currentView === VIEWS.COLUMNS
        },
        {
            key: VIEWS.FILTERS,
            text: 'Filters',
            iconProps: { iconName: 'Filter' },
            onClick: () => setCurrentView(VIEWS.FILTERS),
            disabled: currentView === VIEWS.FILTERS
        },

    ];


    useEffect(() => {
        const temp = {};
        items.forEach((node) => {
            temp[node.key] = false;
        });
        setExpansion(temp);
        setVisibleNodes(nodes);
        setSearchable(initSearch(items));
    }, [nodes, items]);

    const isExpaned = (node) => expanded[node.key] === true;

    const getChildrenToCollapse = (children) => {
        const collapseChild = (child, res) => {
            res.push(child.key);
            child.children.forEach(c => collapseChild(c, res));
        };
        const result = [];
        children.forEach(child => collapseChild(child, result));
        return result;
    }

    const toggleExpansion = (node) => {
        const copy = {...expanded};
        copy[node.key] = !copy[node.key];
        if (copy[node.key] === false) {
            const children = getChildrenToCollapse(node.children);
            children.forEach(child => {
                copy[child] = false;
            });
        }
        setExpansion(copy);
    }

    const getIcon = (type) => {
        if (type === 3) {
            return "More";
        } else if (type === 5) {
            return "Money";
        } else if (type === 8) {
            return "DateTime2";
        } else {
            return "TextField";
        }
    }

    const onSearch = (text) => {
        const copy = JSON.parse(JSON.stringify(nodes));
    
        if (!text || !text.length) {
            setVisibleNodes(copy);
            return;
        }
        if (text.length < 3) {
            return;
        }

        const res = searchable.search(text);
        const results = res.map(i => i.item);
        console.log({results});
        const temp = processNodes(results);
        const searchResults = processNodesForSearch(temp, copy[0]);
        //const searchResults = copy.map(i => processNodesForSearch(temp, i));
        copy[0] = Object.assign({}, searchResults);
        console.log({searchResults});
        setVisibleNodes(copy);
    }

    const onRenderOption = (option) => {
            
        if (option.itemType === SelectableOptionMenuItemType.Header) {
            return (
                <div><SearchBox underlined={true} onChange={(_, val) => onSearch(val)} /></div>
            )
        }
        return (
            <div>
                <span>{option.text}</span>
            </div>
        );
    }


    const RenderNodeTest = ({ node }) => {
        const exp = isExpaned(node);
        const isNode = node.children.length > 0;
        const isSelected = selection.isItemSelected(node);
        return (
            <li onClick={(ev) => { ev.stopPropagation(); selection.toggleSelection(node); }}>
                <div className={classNames.item}>
                    <div className={classNames.check}>
                        <Check checked={isSelected} className="checkmark" />
                    </div>
                    <div>
                       {isNode ? <IconButton iconProps={{iconName: exp ? "ChevronDown" : "ChevronRight" }} onClick={(ev) => {  ev.stopPropagation(); toggleExpansion(node)  } } /> : null}
                    </div>
                    <span style={{marginRight:8}}>
                        <Icon iconName={getIcon(node.type)} className="icon" />
                    </span>
                    <div style={{fontWeight: node.children.length ? '600' : 'normal'}}>
                        <span style={{whiteSpace:'nowrap', width:'100%'}}>
                        {node.type !== 3 ? node.text : ''}
                        </span>
                    </div>
                </div>
                <ul>
                    {exp ? node.children.map((n, i) => <RenderNodeTest key={n.name + i} node={n} />) : null}
                </ul>
            </li>
        )
    }   

    const activeNode = useMemo(() => activeRoot ? visibleNodes.find(i => i.name === activeRoot.key) : null,  [activeRoot, visibleNodes]);

    const Filters = () => {
        return (
            <div>
                {filters.map(filter => {
                    return (
                        <div key={filter.id} style={{marginBottom: 20 }}>
                            <div>
                                <Text>{filter.name}</Text>
                            </div>
                            <div style={{display:'flex'}}>
                                <div style={{flex:0,  minWidth: 128, width: 128}}>
                                    <Dropdown 
                                        options={[ {key : 'StartsWith', text : 'Starts with'} ]}
                                    />
                                </div>
                                <div style={{flex:1}}>
                                    <Dropdown 
                                        options={[ {key : '100', text : '100'}, {key : '200', text : '200'}, {key : '300', text : '300'} ]}
                                    />
                                </div>
                                <div>
                                    <IconButton iconProps={{iconName : 'Trash'}} onClick={() => { setFilters([...filters.filter(i => i.id !== filter.id)]) }} />
                                </div>
                            </div>
                            <div style={{maxWidth: 64, marginTop:10}}>
                                <Dropdown 
                                    options={[ {key: 'IN', text: 'IN'}, {key: 'EQ', text: 'EQ'}, {key: 'AND', text: 'AND'} ]}
                                />
                            </div>
                            <div>

                            </div>
                        </div>
                    )
                })}
                <div>
                    <IconButton iconProps={{iconName : 'Add'}} />
                </div>
            </div>
        )
    }

    return (
        <Stack styles={{root: { width: '100%', padding:10}}}>
            <CommandBar
                styles={{root: { paddingLeft: 0, paddingBottom: 8}}}
                items={_items}
            />
            {currentView === "columns" ?<div>
                <Dropdown
                    placeholder="Select an option"
                    options={roots}
                    onChange={(_, i) => setActiveRoot(i)}
                    onRenderOption={onRenderOption}
                />
                {activeRoot && activeNode && activeNode.children ? <SearchBox underlined={true} onChange={(_, val) => onSearch(val)} /> : null}
            </div> : null}

            <div>
                {currentView === VIEWS.COLUMNS ? <ul className="group group-root" style={{borderCollapse: 'collapse', flex:1}}>
                    {activeRoot && activeNode && activeNode.children ? activeNode.children.map((n, i) => <RenderNodeTest key={n.name + i} node={n} />) : null}
                </ul> : null}
                {currentView === VIEWS.FILTERS ? <Filters /> : null}
            </div>
        </Stack>
    )
}